import { Injectable } from '@angular/core';

export const SIZE_TO_MEDIA = {
    xs: '(min-width: 0px)',
    sm: '(min-width: 576.98px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1024.98px)',
    xxl: '(min-width: 1200px)',
};

@Injectable({
    providedIn: 'root',
})
export class MediaQueryService {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {}

    // Check if the window matches the media query
    // at the breakpoint passed
    // e.g. matchBreakpoint('sm') => true if screen width exceeds 576px
    public matchBreakpoint(breakpoint: string): boolean {
        if (breakpoint === undefined || breakpoint === '') {
            return true;
        }
        if (window.matchMedia) {
            const mediaQuery = SIZE_TO_MEDIA[breakpoint];
            return window.matchMedia(mediaQuery).matches;
        }
        return false;
    }

    public inIntervalBreakpoint(min: string, max: string): boolean {
        return this.matchBreakpoint(min) && !this.matchBreakpoint(max);
    }
}
