import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[scroll]',
})
export class ScrollDirective implements OnDestroy, OnInit {
    @Input()
    debouceTime = 100;

    @Input()
    bottomPercentage = 0.95;

    @Output()
    public scrollToBottom = new EventEmitter<boolean>();

    onScrollBottom$ = new BehaviorSubject(false);

    @HostListener('scroll', ['$event'])
    private onScroll($event: Event) {
        const el = this.el.nativeElement;
        if (el.scrollTop + el.offsetHeight > el.scrollHeight * this.bottomPercentage) {
            this.onScrollBottom$.next(true);
        }
    }

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.onScrollBottom$
            .pipe(
                debounceTime(100),
                tap(() => {
                    this.scrollToBottom.emit(true);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    ngOnDestroy() {}
}
